import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import { ThreeDotsDropdownV3 } from "@circle-react-shared/ThreeDotsDropdownV3";
import type { DropdownOptions } from "@circle-react-shared/uikit/Dropdown";

export const LeaderboardSettingsDropdown = () => {
  const history = useHistory();

  const dropdownOptions: DropdownOptions = [
    {
      label: t("leaderboard.settings"),
      onClick: () => history.push("/settings/gamification"),
      value: "settings",
    },
  ];

  return (
    <ThreeDotsDropdownV3
      label={t("leaderboard.settings")}
      options={dropdownOptions}
      dataTestId="leaderboard-settings"
    />
  );
};
