export const redirectToCheckoutConfirmation = (
  checkoutResponse: any,
  checkoutConfirmationUrl: string,
) => {
  const processorId =
    checkoutResponse?.subscription_processor_id ||
    checkoutResponse?.payment_intent_processor_id ||
    checkoutResponse?.invoice_processor_id;
  const url = `${checkoutConfirmationUrl}${processorId}`;

  window.webview?.paymentSuccessful?.();
  return window.open(url, "_self");
};
