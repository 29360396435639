import { useState } from "react";
import { useMutation } from "react-query";
import { paywallCheckoutApi } from "@circle-react/api/paywallCheckoutApi";
import type {
  PixPrepareResponseEndpoint,
  PrepareRequestEndpoint,
} from "@circle-react/api/paywallCheckoutApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import {
  isRequiresAction,
  isSucceeded,
} from "@circle-react/helpers/paywalls/paymentIntentHelpers";
import { PIX_MODAL_CLOSED, PIX_PAYMENT_EXPIRED } from "../../helpers";
import { redirectToCheckoutConfirmation } from "./helpers";

const throwErrorFromApi = (error: ApiError) => {
  throw new Error(error?.message, {
    cause: error.body,
  });
};

export const usePix = ({ stripe }: any) => {
  const { currentCommunity, checkoutConfirmationUrl } =
    usePaywallCheckoutContext();

  const confirmPixPaymentMutation = useMutation<any, any, any>((params: any) =>
    stripe.confirmPixPayment(params.payment_intent_client_secret, {
      payment_method: {},
    }),
  );

  const [prepareCache, setPrepareCache] = useState<
    PrepareRequestEndpoint | Record<string, never>
  >({});
  const prepareMutation = useMutation<
    PixPrepareResponseEndpoint,
    ApiError,
    PrepareRequestEndpoint
  >(params => paywallCheckoutApi.prepare(params));

  const checkoutMutation = useMutation<any, any, any>(params =>
    paywallCheckoutApi.create({ formData: params }),
  );

  const prepare = async (formData: any) => {
    const {
      name,
      email,
      payment_method_type,
      paywall_price_id,
      coupon_code,
      coupon_code_applied,
      community_member_billing_info_attributes,
    } = formData;

    const params: PrepareRequestEndpoint = {
      name,
      email,
      payment_method_type,
      paywall_price_id,
      community_id: currentCommunity.id,
      coupon_code: coupon_code_applied ? coupon_code : undefined,
      community_member_billing_info_attributes,
    };

    // If the user closes the modal, we need to use the previous payment intent but it is needed
    // to check if the payment intent still valid to be paid, otherwise we create another payment intent
    const isCacheEqualToParams =
      JSON.stringify(prepareCache) === JSON.stringify(params);
    const previousPaymentIntent = isCacheEqualToParams
      ? await stripe.retrievePaymentIntent(
          prepareMutation?.data?.payment_intent_client_secret,
        )
      : null;
    const shouldUsePreviousPayment =
      isCacheEqualToParams &&
      isRequiresAction(previousPaymentIntent?.paymentIntent);

    // We need to check if the `prepareMutation` was called before,
    // this approach re-uses the old `PaymentIntent` created previously if available
    const prepareResponse =
      (shouldUsePreviousPayment && prepareMutation?.data) ||
      (await prepareMutation.mutateAsync(params, {
        onSuccess: () => {
          setPrepareCache(params);
        },
        onError: throwErrorFromApi,
      }));

    if (prepareResponse) {
      const { paymentIntent } = await confirmPixPaymentMutation.mutateAsync(
        prepareResponse,
      );

      if (isRequiresAction(paymentIntent)) {
        throw new Error("Pix modal is closed", {
          cause: PIX_MODAL_CLOSED,
        });
      }

      if (!isSucceeded(paymentIntent)) {
        // Once the PaymentIntent fails, we should reset the mutation
        // to allow the member to re-do the checkout
        prepareMutation.reset();
        setPrepareCache({});
        throw new Error("Pix payment expired", {
          cause: PIX_PAYMENT_EXPIRED,
        });
      }
    }

    return prepareResponse;
  };

  const checkout = async (formData: any, prepareResult: any) =>
    await checkoutMutation.mutateAsync(
      {
        ...formData,
        payment_intent_id: prepareResult.payment_intent_id,
      },
      { onError: throwErrorFromApi },
    );

  const onSubmitMutation = useMutation<any, any, any>(async formData => {
    const prepareResult = await prepare(formData);

    if (prepareResult) {
      const checkoutResponse = await checkout(formData, prepareResult);
      if (checkoutResponse) {
        redirectToCheckoutConfirmation(
          checkoutResponse,
          checkoutConfirmationUrl,
        );
      }

      return checkoutResponse;
    }
  });

  return {
    onSubmitMutation,
    mutations: {
      prepareMutation,
      checkoutMutation,
      confirmPixPaymentMutation,
    },
  };
};
